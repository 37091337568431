import { Component, h } from 'preact'
import { useState } from 'preact/hooks'
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap'
import ThemeContext from '../../context/ThemeContext'

class Header extends Component {

  public render() {
    const [collapsed, setCollapsed] = useState(true)
    const toggleNavbar = () => setCollapsed(!collapsed)

    return (
    <ThemeContext.Consumer>
    {theme => (
      <div>
        <Navbar expand="md" light={!theme.dark} dark={theme.dark}>
          <Container>
            <NavbarBrand href="/">Shamil Nunhuck</NavbarBrand>
            <NavbarToggler className="mr-2" onClick={toggleNavbar} />
            <Collapse isOpen={!collapsed} navbar={true}>
              <Nav className="ml-auto" navbar={true}>
                <NavItem>
                  <NavLink href="/me">About Me</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/curriculum-vitae">CV</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/blog/">Blog</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="dark-switcher" onClick={ theme.toggleDark }>
                    { theme.dark ? <span>☀</span> : <span>☾</span> }
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
      )}
      </ThemeContext.Consumer>
    )
  }
}

export default Header
