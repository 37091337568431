import { createContext, Component, h } from 'preact'

const defaultState = {
  dark: false,
  toggleDark: () => {},
}

const ThemeContext = createContext(defaultState)
const requestsDarkMode = () =>
  window.matchMedia('(prefers-color-scheme: dark)')
  .matches === true

class ThemeProvider extends Component {
    state = {
        dark: false,
    }

    toggleDark = () => {
        const dark = !this.state.dark
        localStorage.setItem('dark', JSON.stringify(dark))
        this.setState({ dark })
    }

    componentDidMount() {
        const isDark = JSON.parse(localStorage.getItem('dark') || 'false')
        
        if (isDark) {
            this.setState({ dark: isDark })
        } else if (requestsDarkMode()) {
            this.setState({ dark: true })
        }
    }
    
    render() {
        const { children } = this.props
        const { dark } = this.state
        return (
          <ThemeContext.Provider value={{ dark, toggleDark: this.toggleDark }}>
            {children}
          </ThemeContext.Provider>
        )
      }
  }
  
  export default ThemeContext
  export { ThemeProvider }